import React from 'react';
import Modal from 'react-modal';

import { ReactComponent as CloseIcon } from 'assets/icons/cross.svg';

import classes from './Modal.module.scss';

Modal.setAppElement('#root');

// type Props = {
//     title: string;
//     contentLabel: string;
//     isOpen: boolean;
//     onClose: () => void;
//     children: React.ReactNode;
// };

const CreateModalView = ({ title, contentLabel, isOpen, onClose, children }) => {
    return (
        <Modal className={classes.modalRoot} isOpen={isOpen} onRequestClose={onClose} contentLabel={contentLabel}>
            <div className={classes.root}>
                <div className={classes.header}>
                    <div className={classes.title}>
                        <h2>{title}</h2>
                    </div>
                    <div className={classes.closeBtnContainer}>
                        <button onClick={onClose} className={classes.closeBtn}>
                            <CloseIcon />
                        </button>
                    </div>
                </div>
                <div className={classes.body}>{children}</div>
            </div>
        </Modal>
    );
};

export default CreateModalView;
