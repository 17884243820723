const scriptId = 'calendlyCalendarEmbedScript';

const loadScriptBookSession = () => {
    if (window.navigator.userAgent.includes('ReactSnap')) {
        console.log('Skipping script load in pre-rendering');
        return;
    }

    if (!document.getElementById(scriptId)) {
        console.log('inside the script');
        const script = document.createElement('script');
        script.id = scriptId;
        script.type = 'text/javascript';
        script.src = 'https://assets.calendly.com/assets/external/widget.js';
        document.head.appendChild(script);
    }
};

export default loadScriptBookSession;
