import React, { useState } from 'react';

import Button from 'UI/Button/Button';
import TextField from 'UI/TextField/TextField';

import classes from '../../pages/Homepage/Homepage.module.scss';

const NewsletterForm = ({ id }) => {
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const handleSubmit = async event => {
        event.preventDefault();
        setIsSubmitting(true);

        const formData = new FormData();
        formData.append('fields[email]', email);

        try {
            const response = await fetch(
                'https://assets.mailerlite.com/jsonp/872197/forms/115896366768588423/subscribe',
                {
                    method: 'POST',
                    body: formData
                }
            );

            if (response.ok) {
                setShowSuccessMessage(true);
            } else {
                alert('There was an error. Please try again.');
            }
        } catch (error) {
            alert('There was an error. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    if (showSuccessMessage) {
        return (
            <div>
                <p className={classes.paragraph}>Thank you! You have successfully subscribed.</p>
            </div>
        );
    }

    return (
        <div className={classes.newsletterRoot}>
            <div className={classes.newsletterTextWrapper}>
                <h3 className={classes.subtitle}>Newsletter</h3>
                <p className={classes.paragraph}>Subscribe for updates, useful tips, and upcoming events!</p>
            </div>
            <div className={classes.newsletterBodyWrapper}>
                <form onSubmit={handleSubmit}>
                    <div className={classes.newsletterForm}>
                        <TextField
                            type="email"
                            id={id + '-email-field'}
                            hideLabel
                            name="fields[email]"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            disabled={isSubmitting}
                            required
                            placeholder="Email Address"
                        />
                        <div className={classes.newsletterSubmitButton}>
                            <Button type="submit" id={id} disabled={isSubmitting} isLoading={isSubmitting}>
                                Submit
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default NewsletterForm;
