const endpoint = 'https://wm6j4udzjbsyhunrao2bvm5r4m0szaac.lambda-url.us-west-1.on.aws';

export const sendContactEmail = body =>
    fetch(endpoint, {
        method: 'POST',
        body: JSON.stringify(body)
    }).then(response => {
        if (!response.ok) throw new Error('Error in fetch');
        return response.json();
    });
