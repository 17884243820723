import React, { useState } from 'react';

import Button from 'UI/Button/Button';
import Modal from 'UI/Modal/Modal';
import ErrorBox from 'UI/ErrorBox/ErrorBox';
import TextField from 'UI/TextField/TextField';
import isEmailValid from 'utils/isEmailValid';
import TextArea from 'UI/TextArea/TextArea';
import { sendContactEmail } from 'api/sendContactEmail';

import { ReactComponent as ExternalLink } from 'assets/icons/externalLink.svg';

import classes from './ModalSendEmail.module.scss';

const ModalSendEmail = ({ isOpen, onClose }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [messageSent, setMessageSent] = useState(false);

    const clearInputData = () => {
        setName('');
        setEmail('');
        setMessage('');
        setErrorMessage('');
        setIsLoading(false);
    };

    const getPhoneCallScheduleLink = () => (
        <a
            className={classes.contractLink3}
            href="https://calendly.com/stas-meeting/30min/"
            id="modal-contact-link-phone-call"
            target="_blank"
            rel="noreferrer"
        >
            phone call <ExternalLink />
        </a>
    );

    const handleSubmit = async () => {
        setErrorMessage('');
        setMessageSent(false);
        setIsLoading(true);
        if (!name || !email || !message) {
            setErrorMessage('Please fill all the fields');
            setIsLoading(false);
            return;
        }
        if (!isEmailValid(email)) {
            setErrorMessage('Please provide valid email address');
            setIsLoading(false);
            return;
        }
        sendContactEmail({
            senderName: name,
            senderContact: email,
            message
        })
            .then(res => {
                console.log('success!', res);
            })
            .catch(() => {
                setErrorMessage('Something happened, and your message was not sent. Please try again later.');
                setIsLoading(false);
            });
        clearInputData();
        setMessageSent(true);
    };

    return (
        <Modal title="Contact Me" contentLabel="Contant Me" isOpen={isOpen} onClose={onClose}>
            <div className={classes.root}>
                <div className={classes.body}>
                    <div className={classes.infoBox}>
                        Please fill out the form with any questions you have. Be sure to provide a valid email address,
                        and I will reply to you as soon as possible!
                    </div>
                    <div className={classes.infoBox}>
                        Do you want me to call instead? Book a 30-minute {getPhoneCallScheduleLink()}.
                    </div>
                    {errorMessage && <ErrorBox error={errorMessage} />}
                    {messageSent && (
                        <div className={classes.success}>
                            Thank you! Your message has been sent successfully. I appreciate your interest and will get
                            back to you shortly.
                        </div>
                    )}
                    <TextField
                        id="contact-name"
                        type="text"
                        label="Your name"
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                    <TextField
                        id="contact-email"
                        type="text"
                        label="Email address"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                    <TextArea
                        id="contact-message"
                        label="Message"
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                    />
                </div>
                <div className={classes.footer}>
                    <Button design="secondary" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button id="modal-contact-button-submit" onClick={handleSubmit} isLoading={isLoading}>
                        Submit
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ModalSendEmail;
