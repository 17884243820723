import React from 'react';

import classes from './ErrorBox.module.scss';

// type Props = {
//     error: string;
// };

const ErrorBox = ({ error }) => {
    return <div className={classes.root}>{error}</div>;
};

export default ErrorBox;
