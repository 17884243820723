import React from 'react';

import classes from './TextArea.module.scss';

// type Props = {
//     id: string;
//     value: string;
//     onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
//     label: string;
//     disabled?: boolean;
//     placeholder?: string;
//     attributes?: {};
// };

const TextArea = ({ id, value, onChange, label, disabled = false, placeholder, attributes }) => {
    return (
        <div className={classes.root}>
            <label className={classes.label} htmlFor={id}>
                {label}
            </label>
            <textarea
                className={classes.textarea}
                id={id}
                name={id}
                value={value}
                onChange={onChange}
                disabled={disabled}
                placeholder={placeholder}
                {...attributes}
            />
        </div>
    );
};

export default TextArea;
