import React from 'react';

import classes from './TextField.module.scss';

// type Props = {
//     id: string;
//     type?: 'text' | 'password' | 'email';
//     value: string;
//     onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
//     label: string;
//     hideLabel?: boolean;
//     disabled?: boolean;
//     attributes?: {};
// };

const TextField = ({
    id,
    type = 'text',
    value,
    onChange = () => {},
    label,
    hideLabel = false,
    disabled = false,
    placeholder = '',
    attributes
}) => {
    return (
        <div className={classes.root}>
            <label className={`${classes.label} ${hideLabel && classes.hideLabel}`} htmlFor={id}>
                {label}
            </label>
            <input
                className={`${classes.textfield} ${hideLabel && classes.hideLaberText}`}
                type={type}
                id={id}
                name={id}
                value={value}
                onChange={onChange}
                disabled={disabled}
                placeholder={placeholder}
                {...attributes}
            />
        </div>
    );
};

export default TextField;
