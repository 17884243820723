import React from 'react';

import classNames from 'classnames';

import { ReactComponent as Loading } from 'assets/icons/Loading.svg';

import classes from './Button.module.scss';

// type Props = {
//     type?: 'button' | 'submit';
//     design?: 'primary' | 'secondary';
//     onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
//     className?: string;
//     children: React.ReactNode;
//     isLoading?: boolean;
//     disabled?: boolean;
// };

const Button = ({
    type = 'button',
    design = 'primary',
    onClick = () => {},
    id = '',
    className = '',
    children,
    isLoading = false,
    disabled = false
}) => {
    return (
        <button
            id={id}
            className={classNames(classes.root, classes[design], className)}
            type={type}
            onClick={onClick}
            disabled={isLoading || disabled}
        >
            {isLoading ? <Loading className={classes.icon} /> : children}
        </button>
    );
};

export default Button;
