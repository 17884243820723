import { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import loadScriptBookSession from 'utils/loadScriptBookSession';
import ModalSendEmail from 'components/ModalSendEmail/ModalSendEmail';
import NewsletterForm from 'components/NewsletterForm/NewsletterForm';
import { BUCKET_URL } from 'constants';

import { ReactComponent as QuestionIcon } from 'assets/icons/question.svg';
import { ReactComponent as Checkmark } from 'assets/icons/checkmark.svg';
import { ReactComponent as ExternalLink } from 'assets/icons/externalLink.svg';
import { ReactComponent as NavOpen } from 'assets/icons/navOpen.svg';
import { ReactComponent as NavClose } from 'assets/icons/navClose.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/instagram.svg';
import { ReactComponent as LinkedInIcon } from 'assets/icons/linkedin.svg';
import { ReactComponent as MediumIcon } from 'assets/icons/medium.svg';
import { ReactComponent as YouTubeIcon } from 'assets/icons/youtube.svg';

import classes from './Homepage.module.scss';

const rates = [
    {
        name: 'stretching',
        rate: '150',
        packages: [
            {
                type: '3month',
                href: 'https://app.paperbell.com/checkout/packages/82611'
            },
            {
                type: '6month',
                href: 'https://app.paperbell.com/checkout/packages/82612'
            },
            {
                type: 'single',
                href: 'https://app.paperbell.com/checkout/packages/82613'
            }
        ]
    },
    {
        name: 'sustainable',
        rate: '225',
        packages: [
            {
                type: '3month',
                href: 'https://app.paperbell.com/checkout/packages/82614'
            },
            {
                type: '6month',
                href: 'https://app.paperbell.com/checkout/packages/82615'
            },
            {
                type: 'single',
                href: 'https://app.paperbell.com/checkout/packages/82616'
            }
        ]
    },
    {
        name: 'comfortable',
        rate: '300',
        packages: [
            {
                type: '3month',
                href: 'https://app.paperbell.com/checkout/packages/82617'
            },
            {
                type: '6month',
                href: 'https://app.paperbell.com/checkout/packages/82618'
            },
            {
                type: 'single',
                href: 'https://app.paperbell.com/checkout/packages/82619'
            }
        ]
    }
];

const Homepage = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isNavOpen, setNavIsOpen] = useState(false);
    const [rate, setRate] = useState('');
    const coachingRef = useRef(null);
    // const videosRef = useRef(null);
    const eventsRef = useRef(null);
    const aboutRef = useRef(null);
    const storiesRef = useRef(null);
    const packagesRef = useRef(null);
    const sessionRef = useRef(null);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const toggleNavMenu = () => {
        setNavIsOpen(!isNavOpen);
    };

    const handleSelectChange = e => {
        setRate(e.target.value);
    };

    const handleRateClick = (type, rate) => {
        const url = rates.find(item => item.rate === rate).packages.find(item => item.type === type).href;
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const executeScroll = useCallback(
        ref => () => {
            if (ref.current) {
                ref.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
            }
        },
        []
    );

    const getACELink = () => (
        <a
            id="main-link-academy"
            href="https://www.acecoachtraining.com/"
            target="_blank"
            rel="noreferrer"
            className={classes.contractLink2}
        >
            Academy for Coaching Excellence <ExternalLink />
        </a>
    );

    const getVoyageLAInterviewLink = () => (
        <a
            id="main-link-voyagela-interview"
            href="https://voyagela.com/interview/exploring-life-business-with-stas-kurgansky-of-stas-kurgansky-coaching-llc/"
            target="_blank"
            rel="noreferrer"
            className={classes.contractLink4}
        >
            "Exploring Life & Business with Stas Kurgansky" <ExternalLink />
        </a>
    );

    const getICFLink = () => (
        <a
            className={classes.contractLink3}
            href="https://www.coachingfederation.org/"
            id="main-link-coaching-federation"
            target="_blank"
            rel="noreferrer"
        >
            International Coaching Federation <ExternalLink />
        </a>
    );

    const getPhoneCallScheduleLink = id => (
        <button id={id} onClick={executeScroll(sessionRef)} className={classes.phoneCallButtonLink}>
            Book an appointment
        </button>
    );

    useEffect(() => {
        if (isNavOpen || isModalOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        // Clean up function
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [isNavOpen, isModalOpen]);

    useEffect(() => {
        // do not run when in the react-snap mode
        if (!window.navigator.userAgent.includes('ReactSnap')) {
            loadScriptBookSession();
        }
    }, []);

    // useEffect(() => {
    //     const handleIframeMessage = event => {
    //         if (event.origin !== 'https://embed.paperbell.com') return;

    //         window.dataLayer = window.dataLayer || [];
    //         window.dataLayer.push({
    //             event: 'iframeCalendarEvent',
    //             iframeMessage: event.data
    //         });
    //     };

    //     window.addEventListener('message', handleIframeMessage);

    //     return () => {
    //         window.removeEventListener('message', handleIframeMessage);
    //     };
    // }, []);

    return (
        <div className={classes.root}>
            <header className={classes.header}>
                <div className={classes.headerLeft}>
                    <div className={classes.containerLogo}>
                        <a id="header-link-main-logo" href="/" className={classes.logoLink}>
                            <img className={classes.logoImg} src={BUCKET_URL + 'tree_logo.png'} alt="Tree logo" />
                        </a>
                    </div>
                    <div className={classes.headerTitleContainer}>
                        <div className={classes.headerTitle}>Stas Kurgansky Coaching LLC</div>
                        <div className={classes.headerSubtitle}>Coaching Services & Consulting</div>
                    </div>
                </div>
                <div className={classes.headerRight}>
                    <a
                        id="header-nav-link-client-login"
                        href="https://app.paperbell.com/customer/sessions/emails/new"
                        rel="noreferrer"
                        target="_blank"
                        className={classes.headerNavButton}
                    >
                        Client Login <ExternalLink />
                    </a>
                    <button
                        id="header-nav-button-coaching"
                        onClick={executeScroll(coachingRef)}
                        className={classes.headerNavButton}
                    >
                        Coaching
                    </button>
                    {/* <button
                        id="header-nav-button-videos"
                        onClick={executeScroll(videosRef)}
                        className={classes.headerNavButton}
                    >
                        Videos
                    </button> */}
                    <button
                        id="header-nav-button-about"
                        onClick={executeScroll(aboutRef)}
                        className={classes.headerNavButton}
                    >
                        About
                    </button>
                    <button
                        id="header-nav-button-events"
                        onClick={executeScroll(eventsRef)}
                        className={classes.headerNavButton}
                    >
                        Events
                    </button>
                    <button
                        id="header-nav-button-stories"
                        onClick={executeScroll(storiesRef)}
                        className={classes.headerNavButton}
                    >
                        Stories
                    </button>
                    <button
                        id="header-nav-button-packages"
                        onClick={executeScroll(packagesRef)}
                        className={classes.headerNavButton}
                    >
                        Packages & Rates
                    </button>
                    <button
                        id="header-nav-button-book-session"
                        onClick={executeScroll(sessionRef)}
                        className={classNames(classes.button, classes.buttonPrimary)}
                    >
                        Book an Appointment
                    </button>
                </div>
                <button
                    id="header-button-hamburger"
                    className={classNames(classes.hamMenu, isNavOpen && classes.hamMenuOpen)}
                    onClick={toggleNavMenu}
                >
                    {isNavOpen ? (
                        <NavClose className={classNames(classes.navIcon, classes.navCloseIcon)} />
                    ) : (
                        <NavOpen className={classNames(classes.navIcon, classes.navOpenIcon)} />
                    )}
                </button>
                <div className={classNames(classes.hamNav, isNavOpen && classes.hamNavActive)}>
                    <nav>
                        <ul>
                            <li>
                                <a
                                    id="header-ham-link-client-login"
                                    href="https://app.paperbell.com/customer/sessions/emails/new"
                                    rel="noreferrer"
                                    target="_blank"
                                    className={classes.hamNavLink}
                                >
                                    Client Login <ExternalLink />
                                </a>
                            </li>
                            <li>
                                <button
                                    id="header-ham-button-coaching"
                                    className={classes.hamNavButton}
                                    onClick={executeScroll(coachingRef)}
                                >
                                    Coaching
                                </button>
                            </li>
                            {/* <li>
                                <button
                                    id="header-ham-button-videos"
                                    className={classes.hamNavButton}
                                    onClick={executeScroll(videosRef)}
                                >
                                    Videos
                                </button>
                            </li> */}
                            <li>
                                <button
                                    id="header-ham-button-about"
                                    className={classes.hamNavButton}
                                    onClick={executeScroll(aboutRef)}
                                >
                                    About
                                </button>
                            </li>
                            <li>
                                <button
                                    id="header-ham-button-events"
                                    className={classes.hamNavButton}
                                    onClick={executeScroll(eventsRef)}
                                >
                                    Events
                                </button>
                            </li>
                            <li>
                                <button
                                    id="header-ham-button-stories"
                                    className={classes.hamNavButton}
                                    onClick={executeScroll(storiesRef)}
                                >
                                    Stories
                                </button>
                            </li>
                            <li>
                                <button
                                    id="header-ham-button-packages"
                                    className={classes.hamNavButton}
                                    onClick={executeScroll(packagesRef)}
                                >
                                    Packages & Rates
                                </button>
                            </li>
                            <li>
                                <button
                                    id="header-ham-button-book-session"
                                    className={classNames(classes.button, classes.buttonPrimary)}
                                    onClick={executeScroll(sessionRef)}
                                >
                                    Book an Appointment
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className={classes.headerSocialContainer}>
                    <a
                        id="header-link-youtube"
                        href="https://www.youtube.com/@StasKurganskyLifeCoach"
                        rel="noreferrer"
                        target="_blank"
                        className={classes.headerIconButton}
                    >
                        <YouTubeIcon />
                    </a>
                    <a
                        id="header-link-medium"
                        href="https://medium.staskurg.com/"
                        rel="noreferrer"
                        target="_blank"
                        className={classes.headerIconButton}
                    >
                        <MediumIcon />
                    </a>
                    <a
                        id="header-link-instagram"
                        href="https://www.instagram.com/staskurgcoach/"
                        rel="noreferrer"
                        target="_blank"
                        className={classes.headerIconButton}
                    >
                        <InstagramIcon />
                    </a>
                    <a
                        id="header-link-linkedin"
                        href="https://www.linkedin.com/in/staskurgcoach/"
                        rel="noreferrer"
                        target="_blank"
                        className={classes.headerIconButton}
                    >
                        <LinkedInIcon />
                    </a>
                </div>
            </header>
            <main>
                <div className={classes.contentContainer}>
                    <section>
                        <div className={classes.sectionHero}>
                            <div className={classes.sectionHeroWrapper}>
                                <div className={classes.sectionHeroContainer}>
                                    <div className={classes.sectionHeroTextBlock}>
                                        <h1 className={classes.heroTitle}>
                                            Coaching for LGBTQ+ Immigrants with Vision
                                        </h1>
                                        <h2 className={classes.heroSubtitle}>
                                            Embrace Authenticity, Gain Clarity, and Find Ease as You Bring Your Vision
                                            to Life
                                        </h2>
                                    </div>
                                    <div className={classes.singleVideoBlock}>
                                        <iframe
                                            className={classes.singleVideoFrame}
                                            src="https://www.youtube.com/embed/RVqKk_QYROY?si=8CxGlM6Fqlii-Np4"
                                            title="YouTube video player"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                    <div className={classes.heroActionBlock}>
                                        {/* <p className={classes.subtitle}>
                                            <a
                                                id="main-hero-link-new-leader"
                                                href="https://coaching-web-sk.s3.us-west-1.amazonaws.com/website-docs/8+Essential+Tips+for+New+Leaders.pdf"
                                                target="_blank"
                                                rel="noreferrer"
                                                className={classes.contractLink}
                                            >
                                                8 Essential Tips for New Leaders <ExternalLink />
                                            </a>
                                        </p> */}
                                        {/* <p className={classes.subtitle}>
                                            <a
                                                id="main-hero-link-career-transition"
                                                href="https://coaching-web-sk.s3.us-west-1.amazonaws.com/website-docs/8+Tips+to+Navigate+Career+Transition+With+Ease.pdf"
                                                target="_blank"
                                                rel="noreferrer"
                                                className={classes.contractLink}
                                            >
                                                8 Tips to Navigate Career Transition With Ease <ExternalLink />
                                            </a>
                                        </p> */}
                                        <p className={classes.subtitle}>
                                            <a
                                                id="main-hero-link-voyagela-interview"
                                                href="https://voyagela.com/interview/exploring-life-business-with-stas-kurgansky-of-stas-kurgansky-coaching-llc/"
                                                target="_blank"
                                                rel="noreferrer"
                                                className={classes.contractLink}
                                            >
                                                My interview in VoyageLA's online magazine <ExternalLink />
                                            </a>
                                        </p>
                                        <div className={classNames(classes.bioButtonWrapper, classes.onlyMobile)}>
                                            <button
                                                id="main-hero-button-book-session"
                                                onClick={executeScroll(sessionRef)}
                                                className={classNames(classes.button, classes.buttonPrimary)}
                                            >
                                                Book an Appointment
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className={`${classes.sectionPadding} ${classes.sectionBio}`}>
                            <h2 className={classes.title}>Reviews</h2>
                            <div className="sk-ww-google-reviews" data-embed-id="25401672"></div>
                        </div>
                    </section>
                    <section ref={coachingRef}>
                        <div className={classes.divider}></div>
                        <div className={`${classes.sectionPadding} ${classes.sectionBio} ${classes.coachingPadding}`}>
                            <h2 className={classes.title}>How Can Coaching Support You?</h2>
                            <div className={classes.bioTwoWrapper}>
                                <div className={classes.bioTwoContainer}>
                                    <div>
                                        {/* <p className={classes.paragraph}>
                                            "When people say they're looking for the meaning of life, what they're
                                            looking for is a deep experience of it." Joseph Campbell
                                        </p> */}
                                        <p className={classes.paragraph}>
                                            My approach is rooted in principles found across both spiritual and secular
                                            traditions, ranging from ancient wisdom teachings to the latest research in
                                            neuroscience. Through coaching, I share an{' '}
                                            <b>elegant framework of tools and practices</b> designed to support you in
                                            living your values, realizing or discovering your vision, and confidently
                                            showing up in the world as your authentic self.
                                        </p>
                                    </div>
                                    <div>
                                        <h3 className={classes.subtitle}>Coaching can be a fit if you are…</h3>
                                        <ul className={classes.bioList}>
                                            <li className={classes.paragraph}>
                                                exhausted by old habits and patterns and want to <b>break free</b> and
                                                live the life you've always wanted;
                                            </li>
                                            <li className={classes.paragraph}>
                                                at a stage where you <b>seek clarity</b> on what is coming next for you;
                                            </li>
                                            <li className={classes.paragraph}>
                                                preparing for a significant <b>life change</b> outside your comfort
                                                zone;
                                            </li>
                                            <li className={classes.paragraph}>
                                                in the middle of a substantial project and need support maintaining{' '}
                                                <b>consistency and focus</b>.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.singleVideoBlock}>
                                <iframe
                                    className={classes.singleVideoFrame}
                                    src="https://www.youtube.com/embed/H-3vQ-nciLE?si=OdVJXOkz99aaWXWO"
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                ></iframe>
                            </div>
                            <div className={classes.benefitsWrapper}>
                                <h3 className={classes.subtitle}>The Four Key Benefits of Coaching</h3>
                                <div className={classes.benefitsContainer}>
                                    <ul className={classes.benefitsList}>
                                        <li className={classes.benefitsListItem}>
                                            <div className={classes.benefitsItem}>
                                                <div className={classes.benefitsItemImgContainer}>
                                                    <img
                                                        className={classes.benefitsItemImg}
                                                        src={BUCKET_URL + 'wisdom.webp'}
                                                        alt="Wisdom"
                                                    />
                                                </div>
                                                <div className={classes.benefitsItemBody}>
                                                    <h4 className={classes.subtitle}>Access Your Own Wisdom</h4>
                                                    <p className={classes.paragraph}>
                                                        Coaching provides a supportive environment for you to tap into
                                                        your innate wisdom, so you can identify what is truly important
                                                        to you and formulate clear life goals.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className={classes.benefitsListItem}>
                                            <div className={classes.benefitsItem}>
                                                <div className={classes.benefitsItemImgContainer}>
                                                    <img
                                                        className={classes.benefitsItemImg}
                                                        src={BUCKET_URL + 'labyrinth.webp'}
                                                        alt="Labyrinth"
                                                    />
                                                </div>
                                                <div className={classes.benefitsItemBody}>
                                                    <h4 className={classes.subtitle}>Discover What Gets in the Way</h4>
                                                    <p className={classes.paragraph}>
                                                        Coaching empowers you to identify and overcome obstacles,
                                                        self-imposed limitations, and outdated patterns, so you can tap
                                                        into your boundless inner potential and accomplish things you
                                                        previously thought were impossible.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className={classes.benefitsListItem}>
                                            <div className={classes.benefitsItem}>
                                                <div className={classes.benefitsItemImgContainer}>
                                                    <img
                                                        className={classes.benefitsItemImg}
                                                        src={BUCKET_URL + 'forward.webp'}
                                                        alt="Climbing"
                                                    />
                                                </div>
                                                <div className={classes.benefitsItemBody}>
                                                    <h4 className={classes.subtitle}>Build Consistency with Ease</h4>
                                                    <p className={classes.paragraph}>
                                                        Coaching guides you in recognizing and taking small, meaningful,
                                                        and consistent steps towards your goals, so you can experience
                                                        the "good tired" of a productive day, instead of the draining
                                                        exhaustion of struggle.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className={classes.benefitsListItem}>
                                            <div className={classes.benefitsItem}>
                                                <div className={classes.benefitsItemImgContainer}>
                                                    <img
                                                        className={classes.benefitsItemImg}
                                                        src={BUCKET_URL + 'partnership.webp'}
                                                        alt="Partnership"
                                                    />
                                                </div>
                                                <div className={classes.benefitsItemBody}>
                                                    <h4 className={classes.subtitle}>Get a Partner Who "Gets" You</h4>
                                                    <p className={classes.paragraph}>
                                                        Coaching establishes a trustful, joyous, empowering, energizing,
                                                        and thought-provoking partnership, so you always have a trusted
                                                        companion by your side, celebrating your successes, big and
                                                        small.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={classes.callToActionContainer}>
                                <p className={classes.paragraph}>
                                    Do you have any questions?{' '}
                                    {getPhoneCallScheduleLink('main-coaching-link-phone-call')}.
                                </p>
                            </div>
                        </div>
                    </section>
                    {/* <section ref={videosRef}>
                        <div className={classes.divider}></div>
                        <div className={`${classes.sectionPadding} ${classes.sectionVideos}`}>
                            <h2 className={classes.title}>Videos</h2>
                            <div className={classes.videosWrapper}>
                                <div className={classes.videosBlock}>
                                    <iframe
                                        className={classes.videosFrame}
                                        src="https://www.youtube.com/embed/fHZJDP3AK7M?si=CuMclqQgl2huFouC"
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                        <div className={`${classes.sectionPadding} ${classes.sectionBio}`}>
                            <div className={classes.callToActionContainer}>
                                <p className={classes.buttonWrapper}>
                                    <button
                                        id="main-videos-button-book-session"
                                        onClick={executeScroll(sessionRef)}
                                        className={classNames(classes.button, classes.buttonPrimary)}
                                    >
                                        Book a Free Session
                                    </button>
                                </p>
                                <p className={classes.paragraph}>
                                    Not ready for a discovery session yet, or have questions? Book a 30-minute{' '}
                                    {getPhoneCallScheduleLink('main-videos-link-phone-call')}.
                                </p>
                            </div>
                        </div>
                    </section> */}
                    <section ref={aboutRef}>
                        <div className={classes.divider}></div>
                        <div className={`${classes.sectionPadding} ${classes.sectionBio}`}>
                            <h2 className={classes.title}>About Me</h2>
                            <div className={classes.bioWrapper}>
                                <div className={classes.bioContainer}>
                                    <div>
                                        <p className={classes.paragraph}>
                                            Hello! My name is <b>Stas</b> (he/him), short for Stanislav. I moved to the
                                            US in 2015. I'm based in <b>Los Angeles, California</b>. I work full-time as
                                            a professional life coach.
                                        </p>
                                        <p className={classes.paragraph}>
                                            While my background is in software and mechanical engineering and I have a
                                            keen interest in math, physics, and science, I've always been{' '}
                                            <b>deeply passionate</b> about personal development, growth, goal
                                            attainment, knowledge-sharing, and encouraging others.
                                        </p>
                                        <p className={classes.paragraph}>
                                            This passion, paired with the experiences from my immigration journey, led
                                            to my decision in January 2023 to pivot from the tech industry to life
                                            coaching. I enrolled in a Coaching Training Program with the {getACELink()}{' '}
                                            (ACE) and completed 145+ hours of rigorous training.
                                        </p>
                                        <p className={classes.paragraph}>
                                            If you want to learn more about me, check out my interview in{' '}
                                            <b>VoyageLA's</b> online magazine, featured in their Inspiring Stories
                                            section: {getVoyageLAInterviewLink()}.
                                        </p>
                                        <p className={classes.paragraph}>
                                            I am fully committed to coaching and am on the path to earning Level 1
                                            certification from the {getICFLink()} (ICF).
                                        </p>
                                        <div>
                                            <h3 className={classes.subtitle}>Identity & Diversity</h3>
                                            <p className={classes.paragraph}>
                                                As a proud member of the LGBTQ community & an immigrant, I love
                                                supporting individuals from diverse backgrounds, life stages &
                                                self-identities, including:
                                            </p>
                                            <ul className={classes.bioList}>
                                                <li className={classes.paragraph}>immigrants, expats and refugees;</li>
                                                <li className={classes.paragraph}>
                                                    first-gens and those who grew up in bilingual or biracial family;
                                                </li>
                                                <li className={classes.paragraph}>
                                                    LGBTQIA2S+ folks (lesbian, gay, bisexual, transgender, queer and/or
                                                    questioning, intersex, asexual, two-spirit, and others);
                                                </li>
                                                <li className={classes.paragraph}>
                                                    BIPOC (Black, Indigenous, & people of color).
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.photoContainer}>
                                    <img
                                        className={classes.photoImg}
                                        src={BUCKET_URL + 'profile_photo.png'}
                                        alt="Profile"
                                    />
                                </div>
                            </div>
                            <h3 className={classes.subtitle}>Credentials & Affiliations</h3>
                            <div className={classes.credsItemsContainer}>
                                <div className={classes.credsItemContainer}>
                                    <a
                                        id="main-about-link-ace"
                                        href="https://acecoachtraining.com/coach-training/coach-training-curriculum/"
                                        target="_blank"
                                        rel="noreferrer"
                                        className={classes.contractLink4}
                                    >
                                        <div className={classes.credsLogoContainer}>
                                            <img
                                                className={classes.credsLogo}
                                                src={BUCKET_URL + 'logos/ace.png'}
                                                alt="Academy for Coaching Excellence Website"
                                            />
                                        </div>
                                    </a>
                                </div>
                                <div className={classes.credsItemContainer}>
                                    <a
                                        id="main-about-link-icf"
                                        href="https://coachingfederation.org/credentials-and-standards/credentials-paths/acc-credential/"
                                        target="_blank"
                                        rel="noreferrer"
                                        className={classes.contractLink4}
                                    >
                                        <div className={classes.credsLogoContainer}>
                                            <img
                                                className={classes.credsLogo}
                                                src={BUCKET_URL + 'logos/icf.png'}
                                                alt="Internation Coaching Federation Website"
                                            />
                                        </div>
                                    </a>
                                </div>
                                <div className={classes.credsItemContainer}>
                                    <a
                                        id="main-about-link-lgbtbe"
                                        href="https://coaching-web-sk.s3.us-west-1.amazonaws.com/website-docs/LGBTBE+certificate.pdf"
                                        target="_blank"
                                        rel="noreferrer"
                                        className={classes.contractLink4}
                                    >
                                        <div className={classes.credsLogoContainer}>
                                            <img
                                                className={classes.credsLogo}
                                                src={BUCKET_URL + 'logos/nglcc.png'}
                                                alt="National LGBTQ Chamber of Commerce Certificate"
                                            />
                                        </div>
                                    </a>
                                </div>
                                <div className={classes.credsItemContainer}>
                                    <a
                                        id="main-about-link-laglcc"
                                        href="https://members.laglcc.org/list/member/stas-kurgansky-coaching-llc-los-angeles-7469"
                                        target="_blank"
                                        rel="noreferrer"
                                        className={classes.contractLink4}
                                    >
                                        <div className={classes.credsLogoContainer}>
                                            <img
                                                className={classes.credsLogo}
                                                src={BUCKET_URL + 'logos/laglcc.png'}
                                                alt="Los Angeles LGBTQ Chamber of Commerce Website"
                                            />
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className={classes.callToActionContainer}>
                                <p className={classNames(classes.buttonWrapper, classes.onlyMobile)}>
                                    <button
                                        id="main-about-button-book-session"
                                        onClick={executeScroll(sessionRef)}
                                        className={classNames(classes.button, classes.buttonPrimary)}
                                    >
                                        Book an Appointment
                                    </button>
                                </p>
                                <p className={classNames(classes.paragraph, classes.onlyDesktop)}>
                                    Do you have any questions?{' '}
                                    {getPhoneCallScheduleLink('main-coaching-link-phone-call')}.
                                </p>
                            </div>
                        </div>
                    </section>
                    <section ref={eventsRef}>
                        <div className={classes.divider}></div>
                        <div className={`${classes.sectionPadding} ${classes.sectionBio}`}>
                            <h2 className={classes.title}>Upcoming Events</h2>
                            <div className={classes.eventsWrapper}>Stay tuned!</div>
                            {/* <div className={classes.eventsWrapper}>
                                <div className={classes.eventsBody}>
                                    <div className={classes.event}>
                                        <div className={classes.eventImageContainer}>
                                            <img
                                                className={classes.eventImage}
                                                src={BUCKET_URL + 'webinar.webp'}
                                                alt="Bulb"
                                            />
                                        </div>
                                        <div className={classes.eventTextContainer}>
                                            <h3 className={classes.cardTitle}>
                                                Webinar: Navigate Career Transition with Ease
                                            </h3>
                                            <p className={classes.cardText}>
                                                Wednesday, April 10
                                                <br /> 4:30 - 5:30pm PDT
                                            </p>
                                        </div>
                                        <div className={classes.eventButtonContainer}>
                                            <a
                                                id="main-link-event-one"
                                                href="https://www.eventbrite.com/e/webinar-navigate-career-transition-with-ease-tickets-871233130747?aff=oddtdtcreator/"
                                                rel="noreferrer"
                                                target="_blank"
                                                className={classNames(classes.button, classes.buttonSecondary)}
                                            >
                                                Learn More
                                            </a>
                                        </div>
                                    </div>
                                    <div className={classes.event}>
                                        <div className={classes.eventImageContainer}>
                                            <img
                                                className={classes.eventImage}
                                                src={BUCKET_URL + 'webinar.webp'}
                                                alt="Winner"
                                            />
                                        </div>
                                        <div className={classes.eventTextContainer}>
                                            <h3 className={classes.cardTitle}>
                                                Webinar: Stepping into a Leadership Role with Certainty
                                            </h3>
                                            <p className={classes.cardText}>
                                                Wednesday, April 17
                                                <br /> 4:30 - 5:30pm PDT
                                            </p>
                                        </div>
                                        <div className={classes.eventButtonContainer}>
                                            <a
                                                id="main-link-event-two"
                                                href="https://www.eventbrite.com/e/webinar-stepping-into-a-leadership-role-with-certainty-tickets-871492446367?aff=oddtdtcreator/"
                                                rel="noreferrer"
                                                target="_blank"
                                                className={classNames(classes.button, classes.buttonSecondary)}
                                            >
                                                Learn More
                                            </a>
                                        </div>
                                    </div>
                                    <div className={classes.event}>
                                        <div className={classes.eventImageContainer}>
                                            <img
                                                className={classes.eventImage}
                                                src={BUCKET_URL + 'webinar.webp'}
                                                alt="Target"
                                            />
                                        </div>
                                        <div className={classes.eventTextContainer}>
                                            <h3 className={classes.cardTitle}>
                                                Webinar: Practical Guide to Mindfulness
                                            </h3>
                                            <p className={classes.cardText}>
                                                Wednesday, April 24
                                                <br /> 4:30 - 5:30pm PDT
                                            </p>
                                        </div>
                                        <div className={classes.eventButtonContainer}>
                                            <a
                                                id="main-link-event-three"
                                                href="https://www.eventbrite.com/e/webinar-practical-guide-to-mindfulness-tickets-871497591757?aff=oddtdtcreator/"
                                                rel="noreferrer"
                                                target="_blank"
                                                className={classNames(classes.button, classes.buttonSecondary)}
                                            >
                                                Learn More
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className={classes.newsletterWrapper}>
                                <NewsletterForm id="main-events-newsletter-button" />
                            </div>
                        </div>
                    </section>
                    <section ref={storiesRef}>
                        <div className={classes.divider}></div>
                        <div className={`${classes.sectionPadding} ${classes.sectionBio}`}>
                            <div>
                                <h2 className={classes.title}>My Stories</h2>
                                <div className={classes.storiesContainer}>
                                    <ul className={classes.storiesList}>
                                        <li className={classes.storiesListItem}>
                                            <div className={classes.storiesItem}>
                                                <div className={classes.storiesItemBody}>
                                                    <h3 className={classes.subtitle}>
                                                        <a
                                                            id="main-link-story-life-update"
                                                            href="https://medium.staskurg.com/summiting-new-heights-a-journey-of-personal-transformation-and-self-discovery-e0d8aeb3e29d"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className={classes.contractLink}
                                                        >
                                                            Summiting New Heights: A Journey of Personal Transformation
                                                            and Self-Discovery <ExternalLink />
                                                        </a>
                                                    </h3>
                                                    <p className={classes.paragraph}>
                                                        In 2023, I left my tech job to navigate my true calling,
                                                        embracing my immigrant background and LGBTQ+ identity as I
                                                        became a life coach.
                                                    </p>
                                                </div>
                                                <div className={classes.storiesItemImgContainer}>
                                                    <img
                                                        className={classes.storiesItemImg}
                                                        src={BUCKET_URL + 'stories/lifeupdate.webp'}
                                                        alt="Life update story thumbnail"
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                        <li className={classes.storiesListItem}>
                                            <div className={classes.storiesItem}>
                                                <div className={classes.storiesItemBody}>
                                                    <h3 className={classes.subtitle}>
                                                        <a
                                                            id="main-link-story-doors"
                                                            href="https://medium.staskurg.com/passing-through-the-doors-3915e608afcd"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className={classes.contractLink}
                                                        >
                                                            Passing Through the Doors <ExternalLink />
                                                        </a>
                                                    </h3>
                                                    <p className={classes.paragraph}>
                                                        In pursuit of happiness and fulfillment, you discover a door
                                                        that can lead you there. The only condition is that you have to
                                                        let go of everything you know about yourself. Will you dare?
                                                    </p>
                                                </div>
                                                <div className={classes.storiesItemImgContainer}>
                                                    <img
                                                        className={classes.storiesItemImg}
                                                        src={BUCKET_URL + 'stories/doors.webp'}
                                                        alt="Passing Through the Doors story thumbnail"
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                        <li className={classes.storiesListItem}>
                                            <div className={classes.storiesItem}>
                                                <div className={classes.storiesItemBody}>
                                                    <h3 className={classes.subtitle}>
                                                        <a
                                                            id="main-link-story-language"
                                                            href="https://medium.staskurg.com/finding-the-path-through-the-discovery-of-foreign-language-02910126d39c"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className={classes.contractLink}
                                                        >
                                                            Finding the Path Through the Discovery of Foreign Language{' '}
                                                            <ExternalLink />
                                                        </a>
                                                    </h3>
                                                    <p className={classes.paragraph}>
                                                        I was eighteen years old. I lived in Russia. I was on the bus,
                                                        riding home after my classes: I witnessed a conversation in
                                                        English for the first time.
                                                    </p>
                                                </div>
                                                <div className={classes.storiesItemImgContainer}>
                                                    <img
                                                        className={classes.storiesItemImg}
                                                        src={BUCKET_URL + 'stories/language.webp'}
                                                        alt="Finding the Path Through the Discovery of Foreign Language thumbnail"
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className={`${classes.sectionPadding} ${classes.sectionBio}`}>
                            <div className={classes.callToActionContainer}>
                                <p className={classNames(classes.buttonWrapper, classes.onlyMobile)}>
                                    <button
                                        id="main-stories-button-book-session"
                                        onClick={executeScroll(sessionRef)}
                                        className={classNames(classes.button, classes.buttonPrimary)}
                                    >
                                        Book an Appointment
                                    </button>
                                </p>
                                <p className={classNames(classes.paragraph, classes.onlyDesktop)}>
                                    Do you have any questions?{' '}
                                    {getPhoneCallScheduleLink('main-coaching-link-phone-call')}.
                                </p>
                            </div>
                        </div>
                    </section>
                    <section ref={packagesRef}>
                        <div className={classes.divider}></div>
                        <div className={`${classes.sectionPadding} ${classes.sectionBio}`}>
                            <div>
                                <h2 className={classes.title}>Packages & Rates</h2>
                                <div className={classes.descriptionWrapper}>
                                    <h3 className={classes.subtitle}>
                                        A simple and straightforward package that includes:
                                    </h3>
                                    <div className={classes.descriptionList}>
                                        <ul>
                                            <li className={classes.paragraph}>
                                                <Checkmark className={classes.checkMark} />A 3-month initial commitment
                                                to ensure you experience tangible results.
                                            </li>
                                            <li className={classes.paragraph}>
                                                <Checkmark className={classes.checkMark} />
                                                Two 55-minute coaching sessions per month, available via phone or video
                                                chat.
                                            </li>
                                            <li className={classes.paragraph}>
                                                <Checkmark className={classes.checkMark} />
                                                Two optional 15-minute support sessions each month to keep you on track.
                                            </li>
                                            <li className={classes.paragraph}>
                                                <Checkmark className={classes.checkMark} />
                                                Unlimited text and email support, along with helpful tools to supplement
                                                your success.
                                            </li>
                                            <li className={classes.paragraph}>
                                                <Checkmark className={classes.checkMark} />A simple contract for maximum
                                                clarity (
                                                <a
                                                    id="main-link-contract"
                                                    href="https://coaching-web-sk.s3.us-west-1.amazonaws.com/website-docs/Coaching+Sample+Contract+2024.pdf"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className={classes.contractLink}
                                                >
                                                    View my sample contract <ExternalLink />
                                                </a>
                                                ).
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className={classes.packagesWrapper}>
                                    <div className={classes.packagesRate}>
                                        <h3 className={classes.subtitle}>
                                            <label htmlFor="rate-select">Select Your Rate:</label>
                                            <select name="rates" id="rate-select" onChange={handleSelectChange}>
                                                <option value="">---</option>
                                                {rates.map((item, i) => (
                                                    <option key={i} value={item.rate}>
                                                        ${item.rate}/session
                                                    </option>
                                                ))}
                                            </select>
                                        </h3>
                                        <p className={classes.paragraph}>
                                            {rate
                                                ? `I am financially ${rates.find(item => item.rate === rate).name}.`
                                                : 'Please, select the rate.'}
                                        </p>
                                    </div>
                                    <div className={classes.packagesBody}>
                                        <div className={classes.package}>
                                            <div className={classes.packageImageContainer}>
                                                <img
                                                    className={classes.packageImage}
                                                    src={BUCKET_URL + 'bulb.webp'}
                                                    alt="Bulb"
                                                />
                                            </div>
                                            <div className={classes.packageTextContainer}>
                                                <h3 className={classes.cardTitle}>6 Coaching Sessions</h3>
                                                <p className={classes.cardText}>
                                                    This package includes a total of 6 coaching sessions over the course
                                                    of 3 months. The investment is 3 payments of{' '}
                                                    <b>{rate ? '$' + Number(rate * 2) : '---'}</b> per month.
                                                </p>
                                            </div>
                                            <div className={classes.packageButtonContainer}>
                                                <button
                                                    id="main-button-package-three"
                                                    onClick={() => handleRateClick('3month', rate)}
                                                    disabled={!rate}
                                                    className={classNames(classes.button, classes.buttonSecondary)}
                                                >
                                                    Book Now
                                                </button>
                                            </div>
                                        </div>
                                        <div className={classes.package}>
                                            <div className={classes.packageImageContainer}>
                                                <img
                                                    className={classes.packageImage}
                                                    src={BUCKET_URL + 'winner.webp'}
                                                    alt="Winner"
                                                />
                                            </div>
                                            <div className={classes.packageTextContainer}>
                                                <h3 className={classes.cardTitle}>12 Coaching Sessions</h3>
                                                <p className={classes.cardText}>
                                                    This package includes a total of 12 coaching sessions over the
                                                    course of 6 months. The investment is 6 payments of{' '}
                                                    <b>{rate ? '$' + Number(rate * 2) : '---'}</b> per month.
                                                </p>
                                            </div>
                                            <div className={classes.packageButtonContainer}>
                                                <button
                                                    id="main-button-package-six"
                                                    onClick={() => handleRateClick('6month', rate)}
                                                    disabled={!rate}
                                                    className={classNames(classes.button, classes.buttonSecondary)}
                                                >
                                                    Book Now
                                                </button>
                                            </div>
                                        </div>
                                        <div className={classes.package}>
                                            <div className={classes.packageImageContainer}>
                                                <img
                                                    className={classes.packageImage}
                                                    src={BUCKET_URL + 'target.webp'}
                                                    alt="Target"
                                                />
                                            </div>
                                            <div className={classes.packageTextContainer}>
                                                <h3 className={classes.cardTitle}>Single Session</h3>
                                                <p className={classes.cardText}>
                                                    For existing clients only. In case you need additional support, the
                                                    cost is <b>{rate ? '$' + rate : '---'}</b> per session.
                                                </p>
                                            </div>
                                            <div className={classes.packageButtonContainer}>
                                                <button
                                                    id="main-button-package-single"
                                                    onClick={() => handleRateClick('single', rate)}
                                                    disabled={!rate}
                                                    className={classNames(classes.button, classes.buttonSecondary)}
                                                >
                                                    Book Now
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section ref={sessionRef}>
                        <div className={classes.divider}></div>
                        <div className={classes.sectionBookSession}>
                            <div
                                className={`${classes.sectionPadding} ${classes.sectionBio} ${classes.sectionCalendar}`}
                            >
                                <h2 className={classes.title}>Book an Appointment</h2>
                                <div
                                    className={classNames(classes.bookSessionBody, 'calendly-inline-widget')}
                                    data-url="https://calendly.com/stas-meeting/30min"
                                    data-resize="true"
                                ></div>
                            </div>
                        </div>
                        <div className={`${classes.sectionBio} ${classes.sectionPadding}`}>
                            <NewsletterForm id="main-discovery-newsletter-button" />
                        </div>
                    </section>
                </div>
                <div className={classes.questionContainer}>
                    <button
                        id="main-button-questions"
                        className={classes.questionButton}
                        type="button"
                        onClick={handleOpenModal}
                    >
                        <span className={classes.questionButtonIcon}>
                            <QuestionIcon />
                        </span>
                        <span className={classes.questionText}>Any Questions?</span>
                    </button>
                </div>
                {isModalOpen && <ModalSendEmail isOpen={isModalOpen} onClose={handleCloseModal} />}
            </main>
            <footer className={classes.footer}>
                <div className={classes.copyright}>Stas Kurgansky Coaching LLC - {new Date().getFullYear()}</div>
                <div className={classes.footerSocialLinks}>
                    <a
                        id="footer-link-youtube"
                        href="https://www.youtube.com/@StasKurganskyLifeCoach"
                        rel="noreferrer"
                        target="_blank"
                        className={classes.footerSocialLink}
                    >
                        <YouTubeIcon /> YouTube
                    </a>
                    <a
                        id="footer-link-medium"
                        href="https://medium.staskurg.com/"
                        rel="noreferrer"
                        target="_blank"
                        className={classes.footerSocialLink}
                    >
                        <MediumIcon /> Medium
                    </a>
                    <a
                        id="footer-link-instagram"
                        href="https://www.instagram.com/staskurgcoach/"
                        rel="noreferrer"
                        target="_blank"
                        className={classes.footerSocialLink}
                    >
                        <InstagramIcon /> Instagram
                    </a>
                    <a
                        id="footer-link-linkedin"
                        href="https://www.linkedin.com/in/staskurgcoach/"
                        rel="noreferrer"
                        target="_blank"
                        className={classes.footerSocialLink}
                    >
                        <LinkedInIcon /> LinkedIn
                    </a>
                </div>
            </footer>
        </div>
    );
};

export default Homepage;
